import OSS from 'ali-oss'
import request from "@/utils/request";


// 获取临时token
export function getSTSToken() {
  return request({
    url: '/common/token/oss',
    method: 'get'
  })
}

export function client(stsToken) {
  console.log('oss临时密钥', stsToken);
  //先请求这个获取临时token的方法,获取到临时token,再初始化oss
  return new OSS({
    //临时token
    stsToken: stsToken.securityToken,
    //临时key
    accessKeyId: stsToken.accessKeyId,
    //临时密钥
    accessKeySecret: stsToken.accessKeySecret,
    bucket: stsToken.bucket,
    endpoint: stsToken.endpoint,
    region: 'oss-cn-zhangjiakou'

  })
}


// oss 域名地址
// const BASE_URL = 'http://static.yhjbt.com/'
const BASE_URL = 'https://cdn.yhjbt.com/'
export function getHeaders() {
  const headers = {
    // 指定该Object被下载时网页的缓存行为。
    "Cache-Control": "no-cache",
    // 指定该Object被下载时的名称。
    "Content-Disposition": "inline",
    // 指定该Object被下载时的内容编码格式。
    "Content-Encoding": "UTF-8",
    // 指定过期时间。
    Expires: "Wed, 08 Jul 2023 16:57:01 GMT",
    // 指定Object的存储类型。
    //"x-oss-storage-class": "Standard",
    // 指定Object的访问权限。
    // "x-oss-object-acl": "private",
    // 设置Object的标签，可同时设置多个标签。
    "x-oss-tagging": "Tag1=1&Tag2=2",
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    "x-oss-forbid-overwrite": "true"
  }
  return headers
}

/**
 * 上传方法
 * @param {filePath} 文件名称
 * @param {file} 文件
 */
export async function uploadObject(filePath, file) {
  let headers = getHeaders()
  let stsToken = await getSTSToken()
  let result = await client(stsToken.data).multipartUpload(filePath, file, {headers})
  if (result.res.status === 200) {
    return BASE_URL + filePath
  }
}

/**
 *  生成文件扩展名称
 * @param {prefix} 路径前缀
 * @param {suffix} 文件后缀
 */
export function generateFilePath(prefix, suffix) {
  let now = new Date()
  let year = now.getFullYear(),
    month = now.getMonth() + 1,
    day = now.getDate()
  let dir = prefix + '/' + year + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) +
    '/' + now.getHours() + '/' + now.getTime() + generateRdStr() + suffix
  return dir
}

/**
 * 生成随机字符串
 * @returns
 */
export function generateRdStr() {
  let len = 15
  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < len; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

